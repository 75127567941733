import './_icon-block.scss'
import { popupController } from "JS/components/_popup";

const iconBlock = function() {
    popupController();
};

document.addEventListener('DOMContentLoaded', function(){
    iconBlock()
}, false);

window.addEventListener('acf/loaded/icon-block', function (e) {
    iconBlock()
});